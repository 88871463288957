// src/components/Accueil.js
import React, { useState, useEffect, useContext } from 'react'; // Importation de React et des hooks useState et useEffect
import Header from '../Mescomposants/Header'
import DetailHeader from '../Mescomposants/DetailHeader'
import TicketOrder from '../Mescomposants/TicketOrder'
import { CartProvider } from '../contexts/CartContext';


import SliderComponent from'../Mescomposants/Slider/SliderComponent'
import PlanSlider from './plansData'

// src/components/Accueil.js
import { Link } from 'react-router-dom'; // Importation de Link de react-router-dom pour la navigation
import Slider from 'react-slick'; // Importation du composant Slider de react-slick
// import Header from '../components/Header'; // Importation du composant Header
// import Footer from '../components/Footer'; // Importation du composant Header
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faThumbsUp, faThumbsDown, faShare, faEllipsisV, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { fetchEvenements } from '../services/apiService'; // Importation de la fonction fetchEvenements depuis le fichier apiService
import { HeaderContext } from '../contexts/HeaderContext'; // Importer le contexte
import { Card, Dropdown, Pagination } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap'; // Assurez-vous d'importer Modal depuis react-bootstrap
import { faWhatsapp, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import ExpectedPremiere from './ExpectedPremiere';



const urlBaseImage = localStorage.getItem("urlBaseImage");
const date = JSON.parse(localStorage.getItem("appDate"));
const mode = JSON.parse(localStorage.getItem("appMode"));



// Slick carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of cards to show at once
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};



const Accueil = () => {
    const [eventDetails, setEventDetails] = useState(null);
    useEffect(() => {
        const params = {
            mode: mode.getEvenementMode,
            LG_EVEID: localStorage.getItem('LG_EVEID')
        };


        fetchEvenements(params)
            .then(response => {
                setEventDetails(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données:', error);
            });
    }, []);

  return (
    <>
    {/* header */}
    <Header  />
    {eventDetails && (
        <>
            <section className="section section--details">
                <div className="container">

                <DetailHeader
                    evenement={eventDetails}
                    first_btn={{ link: "/", name: "Retour à la recherche" }}
                    second_btn={{ link: "/paiement", name: "Retour au paiement" }}
                    // cartItems={cartItems}
                />
                <div className="row">
    {/* title */}
    <div className="col-12">
        <h1 className="section__title section__title--head">
            {/* {eventDetails.STR_EVENAME} */}
        </h1>
    </div>
    {/* end title */}
    {/* content */}
    <div id='left' className="col-12 col-xl-6 order-2 order-xl-1">
        <div className="item item--details">
            <div className="row">
                {/* card cover */}
                <div className="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-6 col-xxl-5">
                    <div className="item__cover">
                        <img src="assets/img/covers/cover1.jpg" alt="" />
                        <span className="item__rate item__rate--green">8.4</span>
                        <button
                            className="item__favorite item__favorite--static"
                            type="button"
                        >
                            <i className="ti ti-bookmark" />
                        </button>
                    </div>
                </div>
                {/* end card cover */}
                {/* card content */}
                <div className="col-12 col-md-7 col-lg-8 col-xl-6 col-xxl-7">
                    <div className="item__content">
                        <ul className="item__meta">
                            <li>
                                <span>Director:</span>{" "}
                                <a href="actor.html">Vince Gilligan</a>
                            </li>
                            <li>
                                <span>Cast:</span> <a href="actor.html">Brian Cranston</a>{" "}
                                <a href="actor.html">Jesse Plemons</a>{" "}
                                <a href="actor.html">Matt Jones</a>{" "}
                                <a href="actor.html">Jonathan Banks</a>{" "}
                                <a href="actor.html">Charles Baker</a>{" "}
                                <a href="actor.html">Tess Harper</a>
                            </li>
                            <li>
                                <span>Genre:</span> <a href="catalog.html">Action</a>
                                <a href="catalog.html">Triler</a>
                            </li>
                            <li>
                                <span>Premiere:</span> 2019
                            </li>
                            <li>
                                <span>Running time:</span> 128 min
                            </li>
                            <li>
                                <span>Country:</span> <a href="catalog.html">USA</a>
                            </li>
                        </ul>
                        <div className="item__description">
                            <p>
                                When a renowned archaeologist goes missing, his daughter
                                sets out on a perilous journey to the heart of the Amazon
                                rainforest to find him. Along the way, she discovers a
                                hidden city and a dangerous conspiracy that threatens the
                                very balance of power in the world. With the help of a
                                charming rogue, she must navigate treacherous terrain and
                                outwit powerful enemies to save her father and uncover the
                                secrets of the lost city. A down-on-his-luck boxer
                                struggles to make ends meet while raising his young son.
                                When an old friend offers him a chance to make some quick
                                cash by fighting in an illegal underground boxing
                                tournament, he sees it as his last shot at redemption. But
                                as the stakes get higher and the fights get more brutal,
                                he must confront his own demons and find the strength to
                                win not just for himself, but for his son.
                            </p>
                            <p>
                                A brilliant scientist discovers a way to harness the power
                                of the ocean's currents to create a new, renewable energy
                                source. But when her groundbreaking technology falls into
                                the wrong hands, she must race against time to stop it
                                from being used for evil. Along the way, she must navigate
                                complex political alliances and confront her own past to
                                save the world from disaster.
                            </p>
                        </div>
                    </div>
                </div>
                {/* end card content */}
            </div>
        </div>
    </div>
    {/* end content */}
    {/* player */}
    <div id="right" className="col-12 col-xl-6 order-1 order-xl-2">
        {/* {eventDetails.STR_EVEDISPLAYROOM === "1" && <SeatMap />} */}
        <CartProvider>
            <TicketOrder eventDetails={eventDetails} />
        </CartProvider>
    </div>
    {/* end player */}
</div>

                </div>
            </section>
            <section className="content">
                <div className="content__head content__head--mt">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* content title */}
                            <h2 className="content__title">Discover</h2>
                            {/* end content title */}
                            {/* content tabs nav */}
                            <ul
                            className="nav nav-tabs content__tabs"
                            id="content__tabs"
                            role="tablist"
                            >
                            <li className="nav-item" role="presentation">
                                <button
                                id="1-tab"
                                className="active"
                                data-bs-toggle="tab"
                                data-bs-target="#tab-1"
                                type="button"
                                role="tab"
                                aria-controls="tab-1"
                                aria-selected="true"
                                >
                                Comments
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                id="2-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#tab-2"
                                type="button"
                                role="tab"
                                aria-controls="tab-2"
                                aria-selected="false"
                                >
                                Reviews
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                id="3-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#tab-3"
                                type="button"
                                role="tab"
                                aria-controls="tab-3"
                                aria-selected="false"
                                >
                                Photos
                                </button>
                            </li>
                            </ul>
                            {/* end content tabs nav */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8">
                    {/* content tabs */}
                    <div className="tab-content">
                        <div
                        className="tab-pane fade show active"
                        id="tab-1"
                        role="tabpanel"
                        aria-labelledby="1-tab"
                        tabIndex={0}
                        >
                        <div className="row">
                            {/* comments */}
                            <div className="col-12">
                            <div className="comments">
                                <ul className="comments__list">
                                <li className="comments__item">
                                    <div className="comments__autor">
                                    <img
                                        className="comments__avatar"
                                        src="assets/img/user.svg"
                                        alt=""
                                    />
                                    <span className="comments__name">John Doe</span>
                                    <span className="comments__time">
                                        30.08.2018, 17:53
                                    </span>
                                    </div>
                                    <p className="comments__text">
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered alteration
                                    in some form, by injected humour, or randomised words
                                    which don't look even slightly believable. If you are
                                    going to use a passage of Lorem Ipsum, you need to be
                                    sure there isn't anything embarrassing hidden in the
                                    middle of text.
                                    </p>
                                    <div className="comments__actions">
                                    <div className="comments__rate">
                                        <button type="button">
                                        <i className="ti ti-thumb-up" />
                                        12
                                        </button>
                                        <button type="button">
                                        7<i className="ti ti-thumb-down" />
                                        </button>
                                    </div>
                                    <button type="button">
                                        <i className="ti ti-arrow-forward-up" />
                                        Reply
                                    </button>
                                    <button type="button">
                                        <i className="ti ti-quote" />
                                        Quote
                                    </button>
                                    </div>
                                </li>
                                <li className="comments__item comments__item--answer">
                                    <div className="comments__autor">
                                    <img
                                        className="comments__avatar"
                                        src="assets/img/user.svg"
                                        alt=""
                                    />
                                    <span className="comments__name">John Doe</span>
                                    <span className="comments__time">
                                        24.08.2018, 16:41
                                    </span>
                                    </div>
                                    <p className="comments__text">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the
                                    industry's standard dummy text ever since the 1500s,
                                    when an unknown printer took a galley of type and
                                    scrambled it to make a type specimen book.
                                    </p>
                                    <div className="comments__actions">
                                    <div className="comments__rate">
                                        <button type="button">
                                        <i className="ti ti-thumb-up" />8
                                        </button>
                                        <button type="button">
                                        3<i className="ti ti-thumb-down" />
                                        </button>
                                    </div>
                                    <button type="button">
                                        <i className="ti ti-arrow-forward-up" />
                                        Reply
                                    </button>
                                    <button type="button">
                                        <i className="ti ti-quote" />
                                        Quote
                                    </button>
                                    </div>
                                </li>
                                <li className="comments__item comments__item--quote">
                                    <div className="comments__autor">
                                    <img
                                        className="comments__avatar"
                                        src="assets/img/user.svg"
                                        alt=""
                                    />
                                    <span className="comments__name">John Doe</span>
                                    <span className="comments__time">
                                        11.08.2018, 11:11
                                    </span>
                                    </div>
                                    <p className="comments__text">
                                    <span>
                                        There are many variations of passages of Lorem Ipsum
                                        available, but the majority have suffered alteration
                                        in some form, by injected humour, or randomised
                                        words which don't look even slightly believable.
                                    </span>
                                    It has survived not only five centuries, but also the
                                    leap into electronic typesetting, remaining
                                    essentially unchanged. It was popularised in the 1960s
                                    with the release of Letraset sheets containing Lorem
                                    Ipsum passages, and more recently with desktop
                                    publishing software like Aldus PageMaker including
                                    versions of Lorem Ipsum.
                                    </p>
                                    <div className="comments__actions">
                                    <div className="comments__rate">
                                        <button type="button">
                                        <i className="ti ti-thumb-up" />
                                        11
                                        </button>
                                        <button type="button">
                                        1<i className="ti ti-thumb-down" />
                                        </button>
                                    </div>
                                    <button type="button">
                                        <i className="ti ti-arrow-forward-up" />
                                        Reply
                                    </button>
                                    <button type="button">
                                        <i className="ti ti-quote" />
                                        Quote
                                    </button>
                                    </div>
                                </li>
                                <li className="comments__item">
                                    <div className="comments__autor">
                                    <img
                                        className="comments__avatar"
                                        src="assets/img/user.svg"
                                        alt=""
                                    />
                                    <span className="comments__name">John Doe</span>
                                    <span className="comments__time">
                                        07.08.2018, 14:33
                                    </span>
                                    </div>
                                    <p className="comments__text">
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered alteration
                                    in some form, by injected humour, or randomised words
                                    which don't look even slightly believable. If you are
                                    going to use a passage of Lorem Ipsum, you need to be
                                    sure there isn't anything embarrassing hidden in the
                                    middle of text.
                                    </p>
                                    <div className="comments__actions">
                                    <div className="comments__rate">
                                        <button type="button">
                                        <i className="ti ti-thumb-up" />
                                        99
                                        </button>
                                        <button type="button">
                                        35
                                        <i className="ti ti-thumb-down" />
                                        </button>
                                    </div>
                                    <button type="button">
                                        <i className="ti ti-arrow-forward-up" />
                                        Reply
                                    </button>
                                    <button type="button">
                                        <i className="ti ti-quote" />
                                        Quote
                                    </button>
                                    </div>
                                </li>
                                <li className="comments__item">
                                    <div className="comments__autor">
                                    <img
                                        className="comments__avatar"
                                        src="assets/img/user.svg"
                                        alt=""
                                    />
                                    <span className="comments__name">John Doe</span>
                                    <span className="comments__time">
                                        02.08.2018, 15:24
                                    </span>
                                    </div>
                                    <p className="comments__text">
                                    Many desktop publishing packages and web page editors
                                    now use Lorem Ipsum as their default model text, and a
                                    search for 'lorem ipsum' will uncover many web sites
                                    still in their infancy. Various versions have evolved
                                    over the years, sometimes by accident, sometimes on
                                    purpose (injected humour and the like).
                                    </p>
                                    <div className="comments__actions">
                                    <div className="comments__rate">
                                        <button type="button">
                                        <i className="ti ti-thumb-up" />
                                        74
                                        </button>
                                        <button type="button">
                                        13
                                        <i className="ti ti-thumb-down" />
                                        </button>
                                    </div>
                                    <button type="button">
                                        <i className="ti ti-arrow-forward-up" />
                                        Reply
                                    </button>
                                    <button type="button">
                                        <i className="ti ti-quote" />
                                        Quote
                                    </button>
                                    </div>
                                </li>
                                </ul>
                                {/* paginator mobile */}
                                <div className="paginator-mob paginator-mob--comments">
                                <span className="paginator-mob__pages">5 of 628</span>
                                <ul className="paginator-mob__nav">
                                    <li>
                                    <a href="#">
                                        <i className="ti ti-chevron-left" />
                                        <span>Prev</span>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <span>Next</span>
                                        <i className="ti ti-chevron-right" />
                                    </a>
                                    </li>
                                </ul>
                                </div>
                                {/* end paginator mobile */}
                                {/* paginator desktop */}
                                <ul className="paginator paginator--comments">
                                <li className="paginator__item paginator__item--prev">
                                    <a href="#">
                                    <i className="ti ti-chevron-left" />
                                    </a>
                                </li>
                                <li className="paginator__item">
                                    <a href="#">1</a>
                                </li>
                                <li className="paginator__item paginator__item--active">
                                    <a href="#">2</a>
                                </li>
                                <li className="paginator__item">
                                    <a href="#">3</a>
                                </li>
                                <li className="paginator__item">
                                    <a href="#">4</a>
                                </li>
                                <li className="paginator__item">
                                    <span>...</span>
                                </li>
                                <li className="paginator__item">
                                    <a href="#">36</a>
                                </li>
                                <li className="paginator__item paginator__item--next">
                                    <a href="#">
                                    <i className="ti ti-chevron-right" />
                                    </a>
                                </li>
                                </ul>
                                {/* end paginator desktop */}
                                <form
                                action="#"
                                className="sign__form sign__form--comments"
                                >
                                <div className="sign__group">
                                    <textarea
                                    id="text"
                                    name="text"
                                    className="sign__textarea"
                                    placeholder="Add comment"
                                    defaultValue={""}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="sign__btn sign__btn--small"
                                >
                                    Send
                                </button>
                                </form>
                            </div>
                            </div>
                            {/* end comments */}
                        </div>
                        </div>
                        <div
                        className="tab-pane fade"
                        id="tab-2"
                        role="tabpanel"
                        aria-labelledby="2-tab"
                        tabIndex={0}
                        >
                        <div className="row">
                            {/* reviews */}
                            <div className="col-12">
                            <div className="reviews">
                                <ul className="reviews__list">
                                <li className="reviews__item">
                                    <div className="reviews__autor">
                                    <img
                                        className="reviews__avatar"
                                        src="assets/img/user.svg"
                                        alt=""
                                    />
                                    <span className="reviews__name">
                                        Best Marvel movie in my opinion
                                    </span>
                                    <span className="reviews__time">
                                        24.08.2018, 17:53 by John Doe
                                    </span>
                                    <span className="reviews__rating reviews__rating--yellow">
                                        6
                                    </span>
                                    </div>
                                    <p className="reviews__text">
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered alteration
                                    in some form, by injected humour, or randomised words
                                    which don't look even slightly believable. If you are
                                    going to use a passage of Lorem Ipsum, you need to be
                                    sure there isn't anything embarrassing hidden in the
                                    middle of text.
                                    </p>
                                </li>
                                <li className="reviews__item">
                                    <div className="reviews__autor">
                                    <img
                                        className="reviews__avatar"
                                        src="assets/img/user.svg"
                                        alt=""
                                    />
                                    <span className="reviews__name">
                                        Best Marvel movie in my opinion
                                    </span>
                                    <span className="reviews__time">
                                        24.08.2018, 17:53 by John Doe
                                    </span>
                                    <span className="reviews__rating reviews__rating--green">
                                        9
                                    </span>
                                    </div>
                                    <p className="reviews__text">
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered alteration
                                    in some form, by injected humour, or randomised words
                                    which don't look even slightly believable. If you are
                                    going to use a passage of Lorem Ipsum, you need to be
                                    sure there isn't anything embarrassing hidden in the
                                    middle of text.
                                    </p>
                                </li>
                                <li className="reviews__item">
                                    <div className="reviews__autor">
                                    <img
                                        className="reviews__avatar"
                                        src="assets/img/user.svg"
                                        alt=""
                                    />
                                    <span className="reviews__name">
                                        Best Marvel movie in my opinion
                                    </span>
                                    <span className="reviews__time">
                                        24.08.2018, 17:53 by John Doe
                                    </span>
                                    <span className="reviews__rating reviews__rating--red">
                                        5
                                    </span>
                                    </div>
                                    <p className="reviews__text">
                                    There are many variations of passages of Lorem Ipsum
                                    available, but the majority have suffered alteration
                                    in some form, by injected humour, or randomised words
                                    which don't look even slightly believable. If you are
                                    going to use a passage of Lorem Ipsum, you need to be
                                    sure there isn't anything embarrassing hidden in the
                                    middle of text.
                                    </p>
                                </li>
                                </ul>
                                {/* paginator mobile */}
                                <div className="paginator-mob paginator-mob--comments">
                                <span className="paginator-mob__pages">5 of 628</span>
                                <ul className="paginator-mob__nav">
                                    <li>
                                    <a href="#">
                                        <i className="ti ti-chevron-left" />
                                        <span>Prev</span>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        <span>Next</span>
                                        <i className="ti ti-chevron-right" />
                                    </a>
                                    </li>
                                </ul>
                                </div>
                                {/* end paginator mobile */}
                                {/* paginator desktop */}
                                <ul className="paginator paginator--comments">
                                <li className="paginator__item paginator__item--prev">
                                    <a href="#">
                                    <i className="ti ti-chevron-left" />
                                    </a>
                                </li>
                                <li className="paginator__item">
                                    <a href="#">1</a>
                                </li>
                                <li className="paginator__item paginator__item--active">
                                    <a href="#">2</a>
                                </li>
                                <li className="paginator__item">
                                    <a href="#">3</a>
                                </li>
                                <li className="paginator__item">
                                    <a href="#">4</a>
                                </li>
                                <li className="paginator__item">
                                    <span>...</span>
                                </li>
                                <li className="paginator__item">
                                    <a href="#">36</a>
                                </li>
                                <li className="paginator__item paginator__item--next">
                                    <a href="#">
                                    <i className="ti ti-chevron-right" />
                                    </a>
                                </li>
                                </ul>
                                {/* end paginator desktop */}
                                <form
                                action="#"
                                className="sign__form sign__form--comments"
                                >
                                <div className="sign__group">
                                    <input
                                    type="text"
                                    className="sign__input"
                                    placeholder="Title"
                                    />
                                </div>
                                <div className="sign__group">
                                    <select
                                    className="sign__select"
                                    name="rating"
                                    id="rating"
                                    >
                                    <option value={0}>Rating</option>
                                    <option value={1}>1 star</option>
                                    <option value={2}>2 stars</option>
                                    <option value={3}>3 stars</option>
                                    <option value={4}>4 stars</option>
                                    <option value={5}>5 stars</option>
                                    <option value={6}>6 stars</option>
                                    <option value={7}>7 stars</option>
                                    <option value={8}>8 stars</option>
                                    <option value={9}>9 stars</option>
                                    <option value={10}>10 stars</option>
                                    </select>
                                </div>
                                <div className="sign__group">
                                    <textarea
                                    id="textreview"
                                    name="textreview"
                                    className="sign__textarea"
                                    placeholder="Add review"
                                    defaultValue={""}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="sign__btn sign__btn--small"
                                >
                                    Send
                                </button>
                                </form>
                            </div>
                            </div>
                            {/* end reviews */}
                        </div>
                        </div>
                        <div
                        className="tab-pane fade"
                        id="tab-3"
                        role="tabpanel"
                        aria-labelledby="3-tab"
                        tabIndex={0}
                        >
                        {/* project gallery */}
                        <div className="gallery" itemScope="">
                            <div className="row">
                            {/* gallery item */}
                            <figure
                                className="col-12 col-sm-6 col-xl-4"
                                itemProp="associatedMedia"
                                itemScope=""
                            >
                                <a
                                href="assets/img/gallery/project-1.jpg"
                                itemProp="contentUrl"
                                data-size="1920x1280"
                                >
                                <img
                                    src="assets/img/gallery/project-1.jpg"
                                    itemProp="thumbnail"
                                    alt="Image description"
                                />
                                </a>
                                <figcaption itemProp="caption description">
                                Some image caption 1
                                </figcaption>
                            </figure>
                            {/* end gallery item */}
                            {/* gallery item */}
                            <figure
                                className="col-12 col-sm-6 col-xl-4"
                                itemProp="associatedMedia"
                                itemScope=""
                            >
                                <a
                                href="assets/img/gallery/project-2.jpg"
                                itemProp="contentUrl"
                                data-size="1920x1280"
                                >
                                <img
                                    src="assets/img/gallery/project-2.jpg"
                                    itemProp="thumbnail"
                                    alt="Image description"
                                />
                                </a>
                                <figcaption itemProp="caption description">
                                Some image caption 2
                                </figcaption>
                            </figure>
                            {/* end gallery item */}
                            {/* gallery item */}
                            <figure
                                className="col-12 col-sm-6 col-xl-4"
                                itemProp="associatedMedia"
                                itemScope=""
                            >
                                <a
                                href="assets/img/gallery/project-3.jpg"
                                itemProp="contentUrl"
                                data-size="1920x1280"
                                >
                                <img
                                    src="assets/img/gallery/project-3.jpg"
                                    itemProp="thumbnail"
                                    alt="Image description"
                                />
                                </a>
                                <figcaption itemProp="caption description">
                                Some image caption 3
                                </figcaption>
                            </figure>
                            {/* end gallery item */}
                            {/* gallery item */}
                            <figure
                                className="col-12 col-sm-6 col-xl-4"
                                itemProp="associatedMedia"
                                itemScope=""
                            >
                                <a
                                href="assets/img/gallery/project-4.jpg"
                                itemProp="contentUrl"
                                data-size="1920x1280"
                                >
                                <img
                                    src="assets/img/gallery/project-4.jpg"
                                    itemProp="thumbnail"
                                    alt="Image description"
                                />
                                </a>
                                <figcaption itemProp="caption description">
                                Some image caption 4
                                </figcaption>
                            </figure>
                            {/* end gallery item */}
                            {/* gallery item */}
                            <figure
                                className="col-12 col-sm-6 col-xl-4"
                                itemProp="associatedMedia"
                                itemScope=""
                            >
                                <a
                                href="assets/img/gallery/project-5.jpg"
                                itemProp="contentUrl"
                                data-size="1920x1280"
                                >
                                <img
                                    src="assets/img/gallery/project-5.jpg"
                                    itemProp="thumbnail"
                                    alt="Image description"
                                />
                                </a>
                                <figcaption itemProp="caption description">
                                Some image caption 5
                                </figcaption>
                            </figure>
                            {/* end gallery item */}
                            {/* gallery item */}
                            <figure
                                className="col-12 col-sm-6 col-xl-4"
                                itemProp="associatedMedia"
                                itemScope=""
                            >
                                <a
                                href="assets/img/gallery/project-6.jpg"
                                itemProp="contentUrl"
                                data-size="1920x1280"
                                >
                                <img
                                    src="assets/img/gallery/project-6.jpg"
                                    itemProp="thumbnail"
                                    alt="Image description"
                                />
                                </a>
                                <figcaption itemProp="caption description">
                                Some image caption 6
                                </figcaption>
                            </figure>
                            {/* end gallery item */}
                            </div>
                        </div>
                        {/* end project gallery */}
                        </div>
                    </div>
                    {/* end content tabs */}
                    </div>
                    {/* sidebar */}
                    <div className="col-12 col-lg-4">
                    <div className="row">
                        {/* section title */}
                        <div className="col-12">
                        <h2 className="section__title section__title--sidebar">
                            You may also like...
                        </h2>
                        </div>
                        {/* end section title */}
                        {/* item */}
                        <div className="col-6 col-sm-4 col-lg-6">
                        <div className="item">
                            <div className="item__cover">
                            <img src="assets/img/covers/cover.jpg" alt="" />
                            <a href="details.html" className="item__play">
                                <i className="ti ti-player-play-filled" />
                            </a>
                            <span className="item__rate item__rate--green">8.4</span>
                            <button className="item__favorite" type="button">
                                <i className="ti ti-bookmark" />
                            </button>
                            </div>
                            <div className="item__content">
                            <h3 className="item__title">
                                <a href="details.html">I Dream in Another Language</a>
                            </h3>
                            <span className="item__category">
                                <a href="#">Action</a>
                                <a href="#">Triler</a>
                            </span>
                            </div>
                        </div>
                        </div>
                        {/* end item */}
                        {/* item */}
                        <div className="col-6 col-sm-4 col-lg-6">
                        <div className="item">
                            <div className="item__cover">
                            <img src="assets/img/covers/cover2.jpg" alt="" />
                            <a href="details.html" className="item__play">
                                <i className="ti ti-player-play-filled" />
                            </a>
                            <span className="item__rate item__rate--green">7.1</span>
                            <button className="item__favorite" type="button">
                                <i className="ti ti-bookmark" />
                            </button>
                            </div>
                            <div className="item__content">
                            <h3 className="item__title">
                                <a href="details.html">Benched</a>
                            </h3>
                            <span className="item__category">
                                <a href="#">Comedy</a>
                            </span>
                            </div>
                        </div>
                        </div>
                        {/* end item */}
                        {/* item */}
                        <div className="col-6 col-sm-4 col-lg-6">
                        <div className="item">
                            <div className="item__cover">
                            <img src="assets/img/covers/cover3.jpg" alt="" />
                            <a href="details.html" className="item__play">
                                <i className="ti ti-player-play-filled" />
                            </a>
                            <span className="item__rate item__rate--red">6.3</span>
                            <button className="item__favorite" type="button">
                                <i className="ti ti-bookmark" />
                            </button>
                            </div>
                            <div className="item__content">
                            <h3 className="item__title">
                                <a href="details.html">Whitney</a>
                            </h3>
                            <span className="item__category">
                                <a href="#">Romance</a>
                                <a href="#">Drama</a>
                                <a href="#">Music</a>
                            </span>
                            </div>
                        </div>
                        </div>
                        {/* end item */}
                        {/* item */}
                        <div className="col-6 col-sm-4 col-lg-6">
                        <div className="item">
                            <div className="item__cover">
                            <img src="assets/img/covers/cover4.jpg" alt="" />
                            <a href="details.html" className="item__play">
                                <i className="ti ti-player-play-filled" />
                            </a>
                            <span className="item__rate item__rate--yellow">6.9</span>
                            <button className="item__favorite" type="button">
                                <i className="ti ti-bookmark" />
                            </button>
                            </div>
                            <div className="item__content">
                            <h3 className="item__title">
                                <a href="details.html">Blindspotting</a>
                            </h3>
                            <span className="item__category">
                                <a href="#">Comedy</a>
                                <a href="#">Drama</a>
                            </span>
                            </div>
                        </div>
                        </div>
                        {/* end item */}
                        {/* item */}
                        <div className="col-6 col-sm-4 col-lg-6">
                        <div className="item">
                            <div className="item__cover">
                            <img src="assets/img/covers/cover5.jpg" alt="" />
                            <a href="details.html" className="item__play">
                                <i className="ti ti-player-play-filled" />
                            </a>
                            <span className="item__rate item__rate--green">8.4</span>
                            <button className="item__favorite" type="button">
                                <i className="ti ti-bookmark" />
                            </button>
                            </div>
                            <div className="item__content">
                            <h3 className="item__title">
                                <a href="details.html">I Dream in Another Language</a>
                            </h3>
                            <span className="item__category">
                                <a href="#">Action</a>
                                <a href="#">Triler</a>
                            </span>
                            </div>
                        </div>
                        </div>
                        {/* end item */}
                        {/* item */}
                        <div className="col-6 col-sm-4 col-lg-6">
                        <div className="item">
                            <div className="item__cover">
                            <img src="assets/img/covers/cover6.jpg" alt="" />
                            <a href="details.html" className="item__play">
                                <i className="ti ti-player-play-filled" />
                            </a>
                            <span className="item__rate item__rate--green">7.1</span>
                            <button className="item__favorite" type="button">
                                <i className="ti ti-bookmark" />
                            </button>
                            </div>
                            <div className="item__content">
                            <h3 className="item__title">
                                <a href="details.html">Benched</a>
                            </h3>
                            <span className="item__category">
                                <a href="#">Comedy</a>
                            </span>
                            </div>
                        </div>
                        </div>
                        {/* end item */}
                    </div>
                    </div>
                    {/* end sidebar */}
                </div>
                </div>
            </section>
        </>
    )}

    <footer className="footer">
        <div className="container">
        <div className="row">
            <div className="col-12">
            <div className="footer__content">
                <a href="index.html" className="footer__logo">
                <img src="assets/assets/img/logo.svg" alt="" />
                </a>
                <span className="footer__copyright">
                © HOTFLIX, 2019—2024 <br /> Create by{" "}
                <a
                    href="https://themeforest.net/user/dmitryvolkov/portfolio"
                    target="_blank"
                >
                    Dmitry Volkov
                </a>
                </span>
                <nav className="footer__nav">
                <a href="about.html">About Us</a>
                <a href="contacts.html">Contacts</a>
                <a href="privacy.html">Privacy policy</a>
                </nav>
                <button className="footer__back" type="button">
                <i className="ti ti-arrow-narrow-up" />
                </button>
            </div>
            </div>
        </div>
        </div>
    </footer>
    </>

  );
};

export default Accueil;
