// src/components/Accueil.js
import React, { useState, useEffect, useContext } from 'react'; // Importation de React et des hooks useState et useEffect
import { Link } from 'react-router-dom'; // Importation de Link de react-router-dom pour la navigation
import Slider from 'react-slick'; // Importation du composant Slider de react-slick
import SliderComponent from './SliderComponent'; // Importation d'un composant personnalisé SliderComponent
import Header from '../components/Header'; // Importation du composant Header
import Footer from '../components/Footer'; // Importation du composant Header
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faThumbsUp, faThumbsDown, faShare, faEllipsisV, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { fetchEvenements } from '../../services/apiService'; // Importation de la fonction fetchEvenements depuis le fichier apiService
import { HeaderContext } from '../../contexts/HeaderContext'; // Importer le contexte
import { Card, Dropdown, Pagination } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap'; // Assurez-vous d'importer Modal depuis react-bootstrap
import { faWhatsapp, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

import './accueil.css';
// import EventAlaUne from './EventAlaUne'



// Définition des images pour le slider


const path_import = "../../eticketAdmin/"
const urlBaseImage = localStorage.getItem("urlBaseImage");
const date = JSON.parse(localStorage.getItem("appDate"));
const mode = JSON.parse(localStorage.getItem("appMode"));


const events = [
  {
    LG_EVEID: "1",
    STR_EVENAME: "Event 1",
    STR_EVEDESCRIPTION: "Description for event 1",
    DT_EVEBEGIN: "2024-09-01",
    HR_EVEBEGIN: "10:00 AM",
    STR_EVEPLACE: "Place 1",
    STR_EVEPIC: "assets/media/image-url-1.jpg",
    STR_EVESTATUTFREE: "0",
  },
  {
    LG_EVEID: "2",
    STR_EVENAME: "Event 2",
    STR_EVEDESCRIPTION: "Description for event 2",
    DT_EVEBEGIN: "2024-09-02",
    HR_EVEBEGIN: "12:00 PM",
    STR_EVEPLACE: "Place 2",
    STR_EVEPIC: "assets/media/image-url-2.jpg",
    STR_EVESTATUTFREE: "1",
  },
  // Add more events as needed
];

// Slick carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Number of cards to show at once
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};


const ModelCard = ({ evenement, index }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    localStorage.setItem('LG_EVEID', evenement.LG_EVEID);
    localStorage.setItem('STR_EVESTATUTFREE', evenement.STR_EVESTATUTFREE);
    
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const generateMessage = () => {
    const title = `*${evenement.STR_EVENAME}*`;
    const description = evenement.STR_EVEDESCRIPTION;
    const date = `*${evenement.DT_EVEBEGIN}*`;
    const time = `*${evenement.HR_EVEBEGIN}*`;
    const location = evenement.STR_EVEPLACE;
    const link = `https://solde.dgbf.ci:8443/bulletin-solde/`; // Replace with your actual link
    const imageUrl = evenement.STR_EVEPIC;
    return `${title}\n\n${description}\n\nDate: ${date} | Heure: ${time}\n\nLieu: ${location}\n\n[Voir l'image](${imageUrl})\n\n${link}`;
  };

  const shareEvent = (platform) => {
    const message = generateMessage();
    let url = '';

    switch(platform) {
      case 'whatsapp':
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
        break;
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://solde.dgbf.ci:8443/bulletin-solde/')}`; // Replace with your actual link
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}&url=${encodeURIComponent('https://solde.dgbf.ci:8443/bulletin-solde/')}`; // Replace with your actual link
        break;
      default:
        break;
    }

    window.open(url, '_blank');
  };
  

  return (
    <div className="item event-item">
      <Link className='w-100 px-3' to={`/detail-event`} onClick={handleClick}>
        <div className="event-grid mb-0">
          <div className="movie-thumb c-thumb">
            <a href="#0">
              <img src={urlBaseImage + evenement.STR_EVEPIC} height={178} alt="movie" />
            </a>
            <div className="event-date">
              <span className={`badge ${evenement.STR_EVESTATUTFREE === "1" ? 'badge-danger' : 'badge-success'} `}>
                {evenement.STR_EVESTATUTFREE === "1" ? 'Payant' : 'Gratuit'}
              </span>
            </div>
          </div>
        </div>
      </Link>
      <div className="movie-content mt-3 pb-2">
        <h5 className="title m-0 text-bolder card-event-name d-flex">
          <Link className='w-100 px-3' to={`/detail-event?id=${evenement.STR_EVENPICTURE}`} onClick={handleClick}>
            <a href="#0" className='truncate-2-lines'>{evenement.STR_EVENAME}</a>
          </Link>
          <FontAwesomeIcon
            icon={faEllipsisV}
            className="text-muted pull-right p-2 share-evente-icone"
            onClick={toggleModal}
          />
        </h5>
        <Link className='w-100 px-3' to={`/detail-event?id=${evenement.STR_EVENPICTURE}`} onClick={handleClick}>

        {/* <div className="fw-semibold fs-5 text-gray-600 text-gray-900 mt-3  truncate-1-lines">
        {evenement.STR_EVEDESCRIPTION}
        </div> */}
        <div className="fs-6 fw-bold mt-1 d-flex flex-stack">
          {/*begin::Label*/}
          <span className="badge fs-2 fw-bold text-gray-900 fs-8 p-0 text-theme">
            <i className="fas fa-calendar-alt fs-8 text-danger"></i><span className='fs-8 text-theme'>{evenement.STR_EVENNAME} {evenement.DT_EVEBEGIN}</span> &nbsp; | &nbsp; <i className="far fa-clock fs-8"></i><span className='fs-8 text-theme'> {evenement.HR_EVEBEGIN}</span>

          </span>
          {/*end::Label*/}
          {/*begin::Action*/}
          {/* <a href="#" className="btn btn-sm btn-light">
            Purchase
          </a> */}
          {/*end::Action*/}
        </div>
        {/* <p className='event-home-desc text-color mt-1 mb-1 px-3 text-left truncate-2-lines'>
          {evenement.STR_EVEDESCRIPTION}
        </p> */}
        <div className="social-and-duration mt-1 px-3">
          <div className="duration-area row">
            {/* <div className="col-lg-12 fs-12 lh-2">
              <i className="fas fa-calendar-alt text-danger"></i><span>{evenement.STR_EVENNAME} {evenement.DT_EVEBEGIN}</span> &nbsp; | &nbsp; <i className="far fa-clock"></i><span> {evenement.HR_EVEBEGIN}</span>
            </div> */}
            <div className="col-lg-12 fs-12 lh-2">
              <span className='text-bold'> {evenement.LG_LSTPLACEID}</span>
            </div>
          </div>
        </div>
        </Link>
      </div>

      <Modal show={showModal} onHide={toggleModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Actions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="link" onClick={() => shareEvent('whatsapp')}>
            <FontAwesomeIcon icon={faWhatsapp} /> Partager sur WhatsApp
          </Button>
          <Button variant="link" onClick={() => shareEvent('facebook')}>
            <FontAwesomeIcon icon={faFacebook} /> Partager sur Facebook
          </Button>
          <Button variant="link" onClick={() => shareEvent('twitter')}>
            <FontAwesomeIcon icon={faTwitter} /> Partager sur Twitter
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  );
};

const CustomArrow = ({ onClick, direction, iconClass }) => {
  return (
    <div className={`cast-${direction}`} onClick={onClick}>
      <i className={iconClass}></i>
    </div>
  );
};

const ModelSlider = ({ evenements, title }) => {
  const groupedModelsByGenre = evenements.reduce((acc, evenement) => {
    if (!acc[evenement.LG_LSTID]) {
      acc[evenement.LG_LSTID] = [];
    }
    acc[evenement.LG_LSTID].push(evenement);
    return acc;
  }, {});

  const nbreLigneAfficher = 1; 
  const nbreColloneAfficher = 4; 

  return (
    <div>
      <div className="section-header-1 ">
        <h5 className="title mt-2">{title}</h5>
      </div>
      {Object.entries(groupedModelsByGenre).map(([LG_LSTID, evenements]) => {
        const rows = [];
        for (let i = 0; i < evenements.length; i += nbreLigneAfficher) {
          rows.push(evenements.slice(i, i + nbreLigneAfficher));
        }

        const sliderSettings = {
          dots: false,
          infinite: evenements.length > nbreColloneAfficher,
          speed: 500,
          slidesToShow: evenements.length >= 1 ? nbreColloneAfficher : evenements.length,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 10000,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ],
          prevArrow: <CustomArrow iconClass="flaticon-double-right-arrows-angles" direction="prev" />,
          nextArrow: <CustomArrow iconClass="flaticon-double-right-arrows-angles" direction="next" />
        };

        return (
          <div className="model-slider" key={LG_LSTID}>
            <div class="d-flex flex-stack mb-5">
                <h3 class="text-gray-900 text-theme">{LG_LSTID}</h3>

                {/* <a href="#" class="fs-6 fw-semibold link-primary">
                    View All Offers
                </a>    */}
            </div>
            <div class="separator separator-dashed mb-9"></div>
            {/* <h6 className='bg-gray-200 border-gray-300 rounded px-7 py-3 mb-6'>{LG_LSTID}</h6> */}
            <Slider className='my-20' {...sliderSettings}>
              {rows.map((row, rowIndex) => (
                <div key={rowIndex} className="model-row">
                  {row.map((evenement, colIndex) => (
                    <div key={colIndex}>
                      <ModelCard evenement={evenement} index={colIndex} />
                    </div>
                  ))}
                </div>
              ))}
            </Slider>
          </div>
        );
      })}
    </div>
  );
};

const Accueil = () => {
  const [activitesData, setData] = useState([]);
  const [eventAlaUneData, setEventAlaUneData] = useState([]); 

  const { searchTerm } = useContext(HeaderContext); 

  const [isLoading, setIsLoading] = useState(true); // Nouveau state pour le chargement

useEffect(() => {
  const params = {
    mode: mode.listEvenementFrontMode,
    DT_BEGIN: date.firstDayOfYear, // 1er jour de l'année en cours
    DT_END: date.today, // la date du jour
  };

  console.log("--------------params");

  console.log(params);

  fetchEvenements(params)
    .then(response => {
      setData(response.data.data);
      setIsLoading(false); // Terminer le chargement
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setIsLoading(false); // Terminer le chargement même en cas d'erreur
    });
}, []);


useEffect(() => {
  const params = {
    mode: mode.listNewsEvenementMode,
    DT_BEGIN: date.firstDayOfYear, // 1er jour de l'année en cours
    DT_END: date.today, // la date du jour
  };

  fetchEvenements(params)
    .then(response => {
      setEventAlaUneData(response.data.data);
      setIsLoading(false); // Terminer le chargement
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setIsLoading(false); // Terminer le chargement même en cas d'erreur
    });
}, []);



if (isLoading) {
  return <div>Loading...</div>; // Affiche un message de chargement pendant la récupération des données
}

  const filteredBlocks = Array.isArray(activitesData) ? activitesData.map((activite) => {
    if (!activite.evenements || !Array.isArray(activite.evenements)) return null; // Vérification supplémentaire
  
    const filteredModels = activite.evenements.filter((evenement) =>
      searchTerm
        .toLowerCase()
        .split(/\s+/)
        .some((word) => evenement.STR_EVENAME.toLowerCase().includes(word) || evenement.STR_EVEDESCRIPTION.toLowerCase().includes(word))
    );
  
    return filteredModels.length > 0 ? { ...activite, evenements: filteredModels } : null;
  }).filter((activite) => activite !== null) : [];
  

  return (
    <>
      <Header /> 
      <div className=' homeslider-section'>
        <SliderComponent numberSlidesToShow={1} /> 
      </div>
      <div className="app container pb-15">

        <div className="section-header-1 ">
          <h5 className="title mt-2">Evenement a la une</h5>
        </div>
        {/* <EventAlaUne /> */}

        <div className="model-carousel mb-5">
          <Slider {...settings}>
            {/* eventAlaUneData */}
            {events.map((event, index) => (
              <ModelCard key={event.LG_EVEID} evenement={event} index={index} />
            ))}
          </Slider>
        </div>

        {filteredBlocks.map((activite) => (
          <ModelSlider key={activite.LG_LSTID} title={activite.STR_LSTDESCRIPTION} evenements={activite.evenements} />
        ))}
      </div>
      <div className='mb-5'></div>
      <Footer /> 
    </>
  );
};

export default Accueil;
