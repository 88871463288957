import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { crudData } from '../../services/apiService';

const SliderComponent = ({ numberSlidesToShow, slideMargin }) => {

  const urlBaseImage = localStorage.getItem("urlBaseImage");
  const date = JSON.parse(localStorage.getItem("appDate"));
  const mode = JSON.parse(localStorage.getItem("appMode"));
  const [sliderData, setData] = useState([]);

  useEffect(() => {
    const params = {
      mode: mode.listBanniereMode,
      DT_BEGIN: date.firstDayOfYear, // 1er jour de l'année en cours
      DT_END: date.today, // la date du jour
    };

    console.log(params);

    crudData(params, "ConfigurationManager.php")
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const CustomArrow = ({ onClick, direction, iconClass }) => {
    return (
      <div className={`cast-${direction}`} onClick={onClick}>
        <i className={iconClass}></i>
      </div>
    );
  };

  console.log(sliderData);

  // Configuration des paramètres du slider
  const settings = {
    dots: false,
    infinite: sliderData.length > 1, // Désactiver le défilement infini s'il n'y a qu'une seule bannière
    autoplay: true,
    speed: 500,
    autoplaySpeed: 7000,
    slidesToShow: Math.min(numberSlidesToShow, sliderData.length), // Afficher le nombre de slides disponibles
    slidesToScroll: 1,
    prevArrow: <CustomArrow iconClass="flaticon-double-right-arrows-angles" direction="prev" />,
    nextArrow: <CustomArrow iconClass="flaticon-double-right-arrows-angles" direction="next" />
  };

  return (
    <Slider {...settings}>
      {sliderData && sliderData.map((image, index) => (
        <div className='h-70 br-15 pt-15' key={index}>
          <section className="details-banner bg_img" style={{
            backgroundImage: `url(${urlBaseImage + image.STR_BANPATH})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}>
            <div className="container banner-heightbook-sections pt-15">
              <img className='card mb-6 img-fluid w-100 h-100' src={urlBaseImage + image.STR_BANPATH} alt={image.STR_BANPATH} />
            </div>
          </section>
        </div>
      ))}
    </Slider>
  );
};

export default SliderComponent;
