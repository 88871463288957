import React, { useState, useEffect, useContext } from 'react'; // Importation de React et des hooks useState et useEffect
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css'; // Import default styles for Splide
import { Link } from 'react-router-dom';
import { faInfoCircle, faThumbsUp, faThumbsDown, faShare, faEllipsisV, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap'; // Assurez-vous d'importer Modal depuis react-bootstrap
import { faWhatsapp, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const EventCarousel = ({ data, ImagelBaseUrl, searchTerm }) => {
  const windowWidth = window.innerWidth;

  const getCarouselType = (length, width) => {
    if (length > 4) return 'loop';
    if (width < 576) return 'loop';
    return 'slide';
  };

  const getPerPage = (length, width) => {
    if (width >= 1200) return 3;
    if (width >= 992) return 2;
    if (width >= 768) return 2;
    if (width >= 576) return 1;
    return 1;
  };

  const getImageName = (imagePath) => {
    const parts = imagePath.split('/');
    return parts[parts.length - 1];
  };




// Filtrer les événements dans chaque catégorie
const filteredData = data
.map((category) => {
  const filteredEvents = category.evenements.filter((event) => {
    const eventName = event?.STR_EVENAME?.toLowerCase() || '';
    const eventDescription = event?.STR_EVEDESCRIPTION?.toLowerCase() || '';
    const eventPlace = event?.LG_LSTPLACEID?.toLowerCase() || '';

    
    const searchTermLower = searchTerm?.toLowerCase() || '';

    // Vérifier si le terme de recherche est dans le nom ou la description de l'événement
    return eventName.includes(searchTermLower) || eventDescription.includes(searchTermLower)  || eventPlace.includes(searchTermLower);
  });

  // Ne retourner la catégorie que si elle contient des événements correspondant
  return { ...category, evenements: filteredEvents };
})
.filter(category => category.evenements.length > 0); // Filtrer les catégories sans événements après recherche


const handleClick = (event) => {
  const { LG_EVEID, STR_EVESTATUTFREE } = event;
  localStorage.setItem('LG_EVEID', LG_EVEID);
  localStorage.setItem('STR_EVESTATUTFREE', STR_EVESTATUTFREE);
};



const [showModal, setShowModal] = useState(false);


const toggleModal = () => {
  setShowModal(!showModal);
};

// const shareEvent = (platform) => {
//   const message = generateMessage();
//   let url = '';

//   switch(platform) {
//     case 'whatsapp':
//       url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
//       break;
//     case 'facebook':
//       url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://solde.dgbf.ci:8443/bulletin-solde/')}`; // Replace with your actual link
//       break;
//     case 'twitter':
//       url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}&url=${encodeURIComponent('https://solde.dgbf.ci:8443/bulletin-solde/')}`; // Replace with your actual link
//       break;
//     default:
//       break;
//   }

//   window.open(url, '_blank');
// };

// const generateMessage = () => {
//   const title = `*${event.STR_EVENAME}*`;
//   const description = event.STR_EVEDESCRIPTION;
//   const date = `*${event.DT_EVEBEGIN}*`;
//   const time = `*${event.HR_EVEBEGIN}*`;
//   const location = event.STR_EVEPLACE;
//   const link = `https://solde.dgbf.ci:8443/bulletin-solde/`; 
//   const imageUrl = event.STR_EVEPIC;
//   return `${title}\n\n${description}\n\nDate: ${date} | Heure: ${time}\n\nLieu: ${location}\n\n[Voir l'image](${imageUrl})\n\n${link}`;
// };
  

  return (
    <section className="section section--border">
      <div className="container">
        {filteredData.map((category) => (
          <div key={category.LG_LSTID}>
            {/* <div className="col-12">
              <div className="section__title-wrap">
                <h2 className="section__title">{category.STR_LSTDESCRIPTION}</h2>
              </div>
            </div> */}

            {category.evenements.length > 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="section__title-wrap">
                  <h2 className="section__title fs-5 text-theme">{category.STR_LSTDESCRIPTION}</h2>

                    {/* <a href="#" className="section__view section__view--carousel">
                      View All
                    </a> */}
                  </div>
                </div>
                <div className="col-12">
                  <Splide
                    options={{
                      type: getCarouselType(category.evenements.length, windowWidth),
                      perPage: getPerPage(category.evenements.length, windowWidth),
                      perMove: 1,
                      arrows: true,
                      pagination: false,
                      drag: true,
                      rewind: false,
                      gap: '24px',
                      autoplay: true,
                      interval: 7000,
                      pauseOnHover: true,
                    }}
                  >
                    {category.evenements.map((event) => (
                      <SplideSlide key={event.LG_EVEID}>
                          <div className="item item--carousel">
                          <Link className="w-100 px-3" to="/detail-event" onClick={() => handleClick(event)}  >

                            <div className="item__cover">
                              <img
                                src={
                                  getImageName(event.STR_EVEPIC) === ''
                                    ? 'assets/img/bg/slide__bg-2.jpg'
                                    : ImagelBaseUrl + event.STR_EVEPIC
                                }
                                alt={event.STR_EVENAME}
                              />

                              <span class="item__rate ">
                              <div className="event-date">
                                <span className={`badge ${event.STR_EVESTATUTFREE === "1" ? 'badge-danger' : 'badge-success'} `}>
                                  {event.STR_EVESTATUTFREE === "1" ? 'Payant' : 'Gratuit'}
                                </span>
                              </div>
                              </span>
                              

                              
                              {/* <a href="details.html" className="item__play">
                                <i className="ti ti-player-play-filled" />
                              </a> */}
                            </div>
                            </Link>
                            <div className="item__content">
                              <div className="movie-content mt-3 pb-2">
                                      <h5 className="item__title text-theme d-flex">
                                        <Link className='w-100 px-3 text-theme' to="/detail-event" onClick={handleClick}>
                                          {event.STR_EVENAME}
                                        </Link>
                                        <FontAwesomeIcon
                                          icon={faEllipsisV}
                                          className="text-muted pull-right p-2 share-evente-icone"
                                          onClick={toggleModal}
                                        />
                                      </h5>
                                      <Link className='text-theme' to="/detail-event" onClick={handleClick}>

                                      <div className="fs-6 fw-bold mt-1 d-flex flex-stack">
                                        {/*begin::Label*/}
                                        {/* <span className="badge fs-2 fw-bold text-gray-900 fs-8 p-0 text-theme">
                                          <i className="fas fa-calendar-alt fs-8 text-danger"></i><span className='fs-8 text-theme'>{event.STR_EVENNAME} {event.DT_EVEBEGIN}</span> &nbsp; | &nbsp; <i className="far fa-clock fs-8"></i><span className='fs-8 text-theme'> {event.HR_EVEBEGIN}</span>

                                        </span> */}
                                        {/*end::Label*/}
                                        {/*begin::Action*/}
                                        {/* <a href="#" className="btn btn-sm btn-light">
                                          Purchase
                                        </a> */}
                                      </div>
                                      {/* <p className='event-home-desc text-color mt-1 mb-1 px-3 text-left truncate-2-lines'>
                                        {evenement.STR_EVEDESCRIPTION}
                                      </p> */}
                                      <div className="social-and-duration mt-1 px-3">
                                        <div className="duration-area row">
                                          {/* <div className="col-lg-12 fs-12 lh-2">
                                            <i className="fas fa-calendar-alt text-danger"></i><span>{evenement.STR_EVENNAME} {evenement.DT_EVEBEGIN}</span> &nbsp; | &nbsp; <i className="far fa-clock"></i><span> {evenement.HR_EVEBEGIN}</span>
                                          </div> */}
                                          <div className="col-lg-12 fs-12 lh-2">
                                            <span className='text-bold'> {event.LG_LSTPLACEID}</span>
                                          </div>
                                        </div>
                                      </div>
                                      </Link>
                              </div>

                              <Modal show={showModal} onHide={toggleModal} centered>
                                <Modal.Header closeButton>
                                  <Modal.Title>Actions</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {/* <Button variant="link" onClick={() => shareEvent('whatsapp')}>
                                    <FontAwesomeIcon icon={faWhatsapp} /> Partager sur WhatsApp
                                  </Button>
                                  <Button variant="link" onClick={() => shareEvent('facebook')}>
                                    <FontAwesomeIcon icon={faFacebook} /> Partager sur Facebook
                                  </Button>
                                  <Button variant="link" onClick={() => shareEvent('twitter')}>
                                    <FontAwesomeIcon icon={faTwitter} /> Partager sur Twitter
                                  </Button> */}
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="secondary" onClick={toggleModal}>
                                    Fermer
                                  </Button>
                                </Modal.Footer>
                              </Modal>
                              {/* <span className="item__category">
                                <a href="#">{event.LG_LSTPLACEID}</a>
                                <a href="#">{event.STR_EVEDESCRIPTION}</a>
                              </span> */}
                            </div>
                          </div>
                        
                      </SplideSlide>
                    ))}
                  </Splide>
                </div>
              </div>
            ) : (
              <p>No events available</p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default EventCarousel;
